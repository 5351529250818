import React from 'react'
import { Stack } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Card, Text } from 'theme-ui'

export default () => {
  
    return (
      <>
        <Seo title='Odpowiedzialnosc' />
        <Divider />
        <Stack effect='fadeInDown'>
          <PageTitle
            header='Odpowiedzialność'
          />
        </Stack>
        <Stack>
        <Card variant='paper'>
          <Text variant='p'>
            <p>Zapoznając się z treścią bloga, rozumiesz, że:</p>
            <ul>
                <li>Cel bloga ma charakter wyłącznie edukacyjny,</li>
                <li>Blog zawiera wyłącznie treść o charakterze ogólnym,</li>
                <li>Blog nie stanowi ani porady prawnej, ani wykładni prawa,</li>
                <li>Blog nie buduje stosunku klient – prawnik.</li>
            </ul>
            <p>Autor bloga nie odpowiada za jego treść w jakimkolwiek zakresie, a w szczególności za jakąkolwiek szkodę wyrządzoną zastosowaniem, lub brakiem zastosowania się do niej. Treść bloga jest wyrazem poglądów autora i nie stanowi oficjalnego stanowiska jego kancelarii. Autor zastrzega sobie prawo do zmiany treści w szczególności w sytuacji zmiany prawa, orzecznictwa oraz praktyki organów administracji.</p>
            <p>Autor bloga ponadto nie odpowiada za treść komentarzy pozostawionych w blogu przez osoby trzecie.</p>
         </Text>
        </Card>
        </Stack>
      </>
    )
  }